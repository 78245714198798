/* eslint-disable jsx-a11y/anchor-is-valid */

import { FileInput } from './fileInput'
import { LinkInput } from './LinksInput'
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import Swal from "sweetalert2";
import { KTSVG } from '../../../../helpers'
import { decryptData, fetchLoginCookie, dynamicIndex, getAllData, checkDuplicateFiles, checkDuplicateLinks, getSharePointIndexFlag, AgentCardResponse, getAgentAliasFlag } from "../../../../../api";
import styles from './Step.module.scss'
import { SharePointInput } from './SharePointInput';
import sharePointLogo from '../../../../../media_dsd/sharepoint.svg'
import { EmailAlias } from './EmailAlias';
import { useParams } from 'react-router-dom';
interface Props4 {
  selectedOption: string
  setSelectedOption: Dispatch<SetStateAction<string>>
  setSelectedAgent?: number
  success?: boolean
  selectedAgent?: number
  setUploadError?: Dispatch<SetStateAction<string>>
  setSuccess?: Dispatch<SetStateAction<boolean>>
  loggedInUserId?: number
  nextStep?: () => void
  uploadError: string;
  onFetchClick?: () => void;
  flag: boolean
  setIsFetchingData?: Dispatch<SetStateAction<boolean>>
  emailAlias?: string;
}

export const Step4 = ({ setIsFetchingData, flag, uploadError, nextStep, loggedInUserId, setSuccess, setUploadError, selectedAgent, success, selectedOption, setSelectedOption, onFetchClick, emailAlias }: Props4) => {
  const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
  const [isDupCheck, setIsDupCheck] = useState<boolean>(false);
  const [youtubeLinks, setYoutubeLinks] = useState<string>('');
  const [webLinks, setWebLinks] = useState<string>('');
  const [textBlob, setTextBlob] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [youtubeUrls, setYoutubeUrls] = useState<string[]>([]);
  const [webUrls, setWebUrls] = useState<string[]>([]);
  const [allData, setAllData] = useState<any[]>([])
  const [showNextButton, setShowNextButton] = useState(false);
  const [showSharePointFlag, setShowSharPointFlag] = useState(false)
  const [showEmailingFlag, setShowEmailingFlag] = useState(false)
  const screenWidth = window.innerWidth;
  const [isClear, setIsClear] = useState<boolean>(false);
  const [showAllLabels, setShowAllLabels] = useState(true);
  const { agentId } = useParams()
  useEffect(() => {
    if (selectedOption === "file" && flag) {
      fetchAllData(Number(selectedAgent));
    }
  }, [selectedOption, selectedAgent]);

  useEffect(() => {
    getsharePointFlag()
    getEmailIndexingFlag()
  }, [])


  const getsharePointFlag = async () => {
    try {
      const flag = await getSharePointIndexFlag()
      setShowSharPointFlag(flag)
    } catch (e) {
      console.error(e)
    }
  }

  
  const getEmailIndexingFlag = async () => {
    try {
      const flag = await getAgentAliasFlag()
      setShowEmailingFlag(flag)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showSharePointFlag) {
        setShowAllLabels(true);
      }
      else {
        setShowAllLabels(false);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [showSharePointFlag]);

  const index = () => {
    setIsFetchingData && setIsFetchingData(true)
    if (selectedOption === 'file') {
      handleSubmitFiles()

    }
    if (selectedOption === 'links') {
      handleSubmitLinks()

    }
    if (selectedOption === 'blob') {
      handleSubmitTextBlobs()

    }


  }

  const handleSubmitFiles = async () => {
    if (setUploadError) {
      setUploadError("")
    }

    if (!selectedAgent || selectedFile.length === 0) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please create/select an agent and add file(s) before indexing.',
      });
      return;
    } else {
      setIsDupCheck(true);
      const duplicateCheckReq = await checkDuplicateFiles(selectedFile, selectedAgent)
      if (duplicateCheckReq?.duplicate_files?.length > 0) {
        let message;
        if (duplicateCheckReq?.duplicate_files?.length === 1) {
          message = `The selected file "${duplicateCheckReq?.duplicate_files[0]}" already exists. Please remove it or choose a new one.`;
        } else {
          const duplicateFileNames = duplicateCheckReq?.duplicate_files?.map(file => `"${file}"`).join(', ');
          message = `The following files already exist: ${duplicateFileNames}. Remove them or choose new ones.`;
        }
        Swal.fire({
          icon: 'warning',
          title: 'File(s) Already Exists',
          text: message,
        });
        setIsDupCheck(false);
        setIsFetchingData && setIsFetchingData(false)

        return;
        // }

      }
      setShowNextButton(true)


      // If file data is not empty and files do not already exist
      Swal.fire({
        text: 'Your File(s) are pending to be indexed.',
        icon: 'success',
        cancelButtonText: 'OK',
      });
      setIsDupCheck(false);
      setSelectedFile([])
      indexFileRequest();
    }
  };

  const handleSubmitLinks = async () => {
    if (
      (!selectedAgent || (youtubeUrls.length === 0 && webUrls.length === 0))
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select agent and add links before indexing.',
      });
      return;
    } else {
      const duplicateCheckReq = await checkDuplicateLinks(youtubeUrls, webUrls, selectedAgent)
      if (duplicateCheckReq?.duplicate_links?.length > 0) {
        let message;
        if (duplicateCheckReq?.duplicate_links?.length === 1) {
          message = `The selected link "${duplicateCheckReq?.duplicate_links[0]}" already exists. Please remove it or choose a new one.`;
        } else {
          const duplicateLinkNames = duplicateCheckReq?.duplicate_links?.map(link => `"${link}"`).join(', ');
          message = `The following links already exist: ${duplicateLinkNames}. Remove them or choose new ones.`;
        }
        Swal.fire({
          icon: 'warning',
          title: 'Link(s) Already Exist',
          text: message,
        });
        setIsFetchingData && setIsFetchingData(false)
        return;
      }

      setShowNextButton(true)
      Swal.fire({
        text: 'Your Link(s) are pending to be indexed.',
        color: "gray",
        icon: 'success',
        cancelButtonText: 'OK',
      });
      setIsClear(true)
      indexRequest();
    }
  };

  const handleSubmitTextBlobs = () => {
    if (
      !selectedAgent ||
      textBlob.length == 0
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select agent and add text before indexing.',
      });
      return;
    } else {
      setShowNextButton(true)
      Swal.fire({
        text: 'Your Blobs are pending to be indexed.',
        color: "gray",
        icon: 'success',
        cancelButtonText: 'OK',
      });
      setIsClear(true)
      indexTextRequest();
    }
  };
  const handleTextBlob = (e: { target: { value: SetStateAction<string>; }; }) => {

    setTextBlob(e.target.value)
  }
  const handleRadioButton = (type: string) => {
    setSelectedOption(type)
    if (setUploadError) {
      setUploadError("")
    }
  }

  const indexFileRequest = async () => {

    setIsLoadingIndex(true);
    if (setUploadError) {
      setUploadError("");
    }

    try {
      if (selectedAgent) {
        const promises = selectedFile.map(file => dynamicIndex(selectedAgent, loggedInUserId!, "file", file));
        const results =  Promise.allSettled(promises);
        setIsLoadingIndex(false);
        setSelectedFile([]);
        setTimeout(() => {
          onFetchClick && onFetchClick!();
        }, 10000);
      }
    }
    catch (error) {

      if (setUploadError) {
        setUploadError("Failed to index the document");
      }
    }
  };

  const indexRequest = async () => {
    setIsLoadingIndex(true);

    if (setUploadError) {
      setUploadError("");
    }

    try {
      if (selectedAgent) {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        if (youtubeUrls.length > 0) {
          const promises = youtubeUrls.map(yt_url => dynamicIndex(selectedAgent, loggedInUserId!, "yt", yt_url));
          const results = await Promise.allSettled(promises);
          setYoutubeUrls([]);


        }

        if (webUrls.length > 0) {
          const promises = webUrls.map(web_url => dynamicIndex(selectedAgent, loggedInUserId!, "web", web_url));
          const results = await Promise.allSettled(promises);
          setWebUrls([]);

        }

        setIsLoadingIndex(false);
        onFetchClick && onFetchClick!();

      }
    } catch (error) {

      setIsLoadingIndex(false);

      if (setUploadError) {
        setUploadError("Failed to index the Links");
      }
    }
  };


  const indexTextRequest = async () => {
    setIsLoadingIndex(true);
    if (setUploadError) {
      setUploadError("");
    }

    try {
      if (selectedAgent) {
        const promises = await dynamicIndex(selectedAgent, loggedInUserId!, "text", textBlob);
        setTextBlob('')
        setIsLoadingIndex(false);
        onFetchClick && onFetchClick!();

      }
    }

    catch (error) {

      setIsLoadingIndex(false);
      if (setUploadError) {
        setUploadError("Failed to index the above text.");
      }

    }
  };

  const fetchAllData = async (id: number, query?: string) => {
    try {
      const response = await getAllData(id, 1, query);

      if (response.data?.length > 0) {

        const filteredData = response.data?.filter(item => (item.blob_url === '' || item.blob_url?.length >= 1) && item.status !== 'Failed');
        const modifiedData = filteredData?.map(item => {

          if (item.file_name) {
            const modifiedFileName = item.file_name.replace(/\d{10}(?=\.)/, '');
            return {
              ...item,
              modified_filename: modifiedFileName,
            };
          }
          return item;
        });

        setAllData(modifiedData);

      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearLinks = () => {
    setYoutubeLinks('');
    setWebLinks('');
    setWebUrls([]);
    setYoutubeUrls([]);
  }

  useEffect(() => {
    const textarea = document.getElementById('textBlobTextarea');
    if (textarea) {
      textarea.style.height = 'auto'; // Reset textarea height
      const newHeight = Math.min(200, textarea.scrollHeight); // Limit to 200px height
      textarea.style.height = `${newHeight}px`; // Set textarea height
    }
  }, [textBlob]);
  return (
    <>
      {/*begin::Step 4 */}

      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row'>
            {/*begin:Option */}

            {showAllLabels ?
              <div>
                <label onClick={() => handleRadioButton("file")} className={`${styles.labelOptions} ${selectedOption === "file" ? styles.selected : ''} d-flex align-items-center justify-content-between cursor-pointer`}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-primary'>
                            <i className='fas fa-file text-primary fs-2x'></i>
                          </span>
                        </span> : <></>}

                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`}>File(s)</span>
                      <span className='fs-7 text-muted' >PDF, Word, Excel, CSV, PPTX, RTF, EML, EPUB, XML, KML, GZ, JSON, Text, HTML, Zip File, Open Document format files are supported</span>
                    </span>
                  </span>
                </label>
                {/*end::Option */}

                {/* Links option */}
                <label onClick={() => handleRadioButton("links")} className={`${styles.labelOptions} ${selectedOption === "links" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer `}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-warning'>
                            <i className='fa-solid fa-link text-warning fs-2x'></i>
                          </span>
                        </span> : <></>
                    }


                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`} >Link(s)</span>
                      {/* <span className='fs-7 text-muted' >Enter YouTube link(s) and Weblink(s) for indexing</span> */}
                      <span className='fs-7 text-muted' >Enter Weblink(s) for indexing</span>
                    </span>
                  </span>

                </label>
                {/* Text option */}
                <label onClick={() => handleRadioButton("blob")} className={`${styles.labelOptions} ${selectedOption === "blob" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer `}>
                  <span className='d-flex align-items-center me-2'>

                    {
                      screenWidth > 670 ?

                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-success  '>
                            <i className='fas fa-file-alt text-success fs-2x'></i>
                          </span>
                        </span> : <></>}

                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`}>Text</span>
                      <span className='fs-7 text-muted' >Enter text</span>
                    </span>
                  </span>

                </label>

                {/* Email Alias option */}
                {
                showEmailingFlag ? 
                <label onClick={() => handleRadioButton("email")} className={`${styles.labelOptions} ${selectedOption === "email" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer  `}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-danger'>
                            <i className='fa-solid fa-envelope text-danger fs-2x'></i>
                          </span>
                        </span> : <></>
                    }
                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`} >Emailing</span>
                      <span className='fs-7 text-muted' >Forward emails and attachments to agent's email address</span>
                    </span>
                  </span>

                </label> : <></>}

                {/* Sharepoint option */}
                <label onClick={() => handleRadioButton("sharepoint")} className={`${styles.labelOptions} ${selectedOption === "sharepoint" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer `}>
                  <span className='d-flex align-items-center me-2'>

                    {
                      screenWidth > 670 ?

                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label' style={{ backgroundColor: 'rgb(200 250 253 / 72%)' }}>
                            {/* <i className='fas fa-file-alt text-success fs-2x'></i> */}
                            <img src={sharePointLogo} style={{ height: '30px', width: '30px' }}></img>
                          </span>
                        </span> : <></>}

                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`}>SharePoint</span>
                      <span className='fs-7 text-muted'>SharePoint folder link</span>
                    </span>
                  </span>

                </label>
              </div>
              :
              <div>
                <label onClick={() => handleRadioButton("file")} className={`${styles.labelOptions} ${selectedOption === "file" ? styles.selected : ''} d-flex align-items-center justify-content-between cursor-pointer `}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-primary'>
                            <i className='fas fa-file text-primary fs-2x'></i>
                          </span>
                        </span> : <></>}

                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`}>File(s)</span>
                      <span className='fs-7 text-muted' >PDF, Word, Excel, CSV, PPTX, RTF, EML, EPUB, XML, KML, GZ, JSON, Text, HTML, Zip File, Open Document format files are supported</span>
                    </span>
                  </span>
                </label>

                <label onClick={() => handleRadioButton("links")} className={`${styles.labelOptions} ${selectedOption === "links" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer  `}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-warning'>
                            <i className='fa-solid fa-link text-warning fs-2x'></i>
                          </span>
                        </span> : <></>
                    }
                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`} >Link(s)</span>
                      {/* <span className='fs-7 text-muted' >Enter YouTube link(s) and Weblink(s) for indexing</span> */}
                      <span className='fs-7 text-muted' >Enter Weblink(s) for indexing</span>
                    </span>
                  </span>

                </label>

                <label onClick={() => handleRadioButton("blob")} className={`${styles.labelOptions} ${selectedOption === "blob" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer  `}>
                  <span className='d-flex align-items-center me-2'>

                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-success  '>
                            <i className='fas fa-file-alt text-success fs-2x'></i>
                          </span>
                        </span> : <></>}

                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`}>Text</span>
                      <span className='fs-7 text-muted' >Enter text</span>
                    </span>
                  </span>

                </label>

                {showEmailingFlag ? 
                <label onClick={() => handleRadioButton("email")} className={`${styles.labelOptions} ${selectedOption === "email" ? styles.selected : ''}  d-flex align-items-center justify-content-between cursor-pointer  `}>
                  <span className='d-flex align-items-center me-2'>
                    {
                      screenWidth > 670 ?
                        <span className='symbol symbol-50px me-6'>
                          <span className='symbol-label bg-light-danger'>
                            <i className='fa-solid fa-envelope text-danger fs-2x'></i>
                          </span>
                        </span> : <></>
                    }
                    <span className='d-flex flex-column'>
                      <span className={`fw-normal fs-6 ${styles.dataSourceName}`} >Emailing</span>
                      <span className='fs-7 text-muted' >Forward emails and attachments to agent's email address</span>
                    </span>
                  </span>

                </label> : <></>}
              </div>
            }
            {selectedOption === 'blob' && (
              <div style={{ minHeight: "150px" }} >
                <textarea
                  id='textBlobTextarea'
                  placeholder='Enter text'
                  value={textBlob}
                  onChange={handleTextBlob}
                  className='form-control form-control-lg form-control-solid fw-normal'
                  style={{ minHeight: '100px', resize: 'none', overflowY: 'auto', scrollbarWidth: "thin" }}
                  rows={3}
                />
              </div>

            )}
            {/* {selectedOption === 'blob' ? <textarea placeholder='Enter text' value={textBlob} onChange={handleTextBlob} className='form-control form-control-lg form-control-solid fw-normal' rows={8} /> : <></>} */}
            {selectedOption === 'file' ? <FileInput setUploadError={setUploadError} isLoadingIndex={isLoadingIndex} index={index} selectedFiles={selectedFile} setSelectedFile={setSelectedFile} disabled={isDupCheck} /> : <></>}
            {selectedOption === 'links' ? <LinkInput setYoutubeLinks={setYoutubeLinks} youtubeLinks={youtubeLinks} setWebLinks={setWebLinks} webLinks={webLinks} setytUrl={setYoutubeUrls} setwebUrl={setWebUrls} clearLinks={clearLinks} isClear={isClear} setIsClear={setIsClear}></LinkInput> : <></>}
            {selectedOption === 'sharepoint' ? <SharePointInput></SharePointInput> : <></>}
            {selectedOption === 'email' ? <EmailAlias emailAlias={emailAlias!}></EmailAlias> : <></>}


            {uploadError ? (
              <div style={{ justifyContent: "center", color: "red" }}>
                <p className='text-center '>{uploadError}</p>
              </div>
            ) : (
              <></>
            )}

          </div>
          <div className='float-end ms-4'>
            {success ? (
              <button
                type='button'
                data-kt-stepper-action='previous'
                className={`btn mt-3 btn-lg ${styles.pointer} ${styles.Btn}`}
                onClick={nextStep}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr064.svg' />
                Next
              </button>
            ) : null}
          </div>
          {((selectedOption === 'blob') || (selectedOption === 'links')) &&
            <>
              <button
                type='button'
                className={`btn btn-lg ${styles.pointer} ${styles.Btn} ${styles.alignRight}`}
                data-kt-stepper-action='previous'
                onClick={index}

              >
                <KTSVG
                  path='/media/icons/duotune/files/fil001.svg'
                  className='svg-icon-3 me-1'
                />
                Upload
              </button>
            </>
          }
        </div>
        {/*end::Form Group */}

      </div>
      {/*end::Step 4 */}
    </>
  )
}


